.screen.print {
  overflow: hidden;
}

.screen.print > main {
  position: relative;
}

.screen.print > main .background-circle {
  width: 1450px;
  height: 1850px;
  position: absolute;
  left: -320px;
  bottom: -1580px;
  background: url("../../../public/circles-3.png") no-repeat center;
  background-size: cover;
}

.stepper-slider,
.print-collections {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.stepper-slider {
  min-width: 1000px;
}

.stepper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  width: calc(100% - 200px);
  min-width: 1030px;
  margin-top: 100px;
}

.stepper > span {
  display: block;
  color: var(--black);
}
.stepper > .separator {
  width: 2.5vw;
  height: 1px;
  background-color: var(--black);
}

.lightbox {
  width: 700px;
  height: 700px;
  background: red;
  opacity: 1;
  position: fixed;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.print-collections {
  row-gap: 150px;
  margin-top: 100px;
}

.print-collections > .collection {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.print-collections > .collection > .content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 2vw;
  row-gap: 50px;
  margin-top: 75px;
}

@media only screen and (max-width: 1120px) {
  .screen.print > main .background-circle {
    width: 725px;
    height: 925px;
    left: -100px;
    bottom: -700px;
  }

  .stepper-slider,
  .print-collections {
    min-width: 0;
  }

  .print-collections {
    row-gap: 100px;
    margin-top: 75px;
  }

  .print-collections > .collection > h4 {
    text-align: center;
  }

  .print-collections > .collection .content {
    justify-content: center;
    column-gap: 2.5vw;
    margin-top: 50px;
  }

  .print-collections > .collection > .content .row {
    row-gap: 2vw;
  }

  .stepper {
    justify-content: center;
    width: calc(100% - 50px);
    flex-wrap: wrap;
    min-width: 0;
    margin-top: 100px;
  }
}

@media only screen and (max-width: 600px) {
  .screen.print > main .background-circle {
    width: 544px;
    height: 692px;
    left: -150px;
    bottom: -550px;
  }

  .stepper {
    width: 100%;
    margin-top: 50px;
  }

  .stepper > .span {
    font-size: var(--font-size-h4);
  }

  .print-collections {
    margin-top: 50px;
  }

  .print-collections > .collection .content {
    justify-content: space-between;
    gap: 10vw;
    margin-top: 30px;
  }
}

.component-print-item {
  text-align: center !important;
}
