.component-layout {
  position: relative;
}

.content-wrapper {
  position: relative;
  top: -240px;
}

.main-link {
  width: 150px;
  height: 200px;
}

.mobile-navigation {
  display: none;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 100px;
  position: fixed;
  top: 0;
  z-index: 2;
  background-color: var(--black);
}

.mobile-navigation .action-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 30px;
}

.links-mobile {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: var(--black);
}

.links-mobile .close-row {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  top: 50px;
  right: 50px;
}

.links-mobile nav {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.links-mobile nav ul {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.links-mobile nav ul li a {
  font-size: var(--font-size-h1);
}

.links-mobile nav ul li a::before {
  content: "";
  width: 100%;
  height: 2px;
  background: var(--white);
  position: absolute;
  left: 0;
  bottom: -8px;
  transform: scaleX(0);
  transform-origin: center left;
  transition: all 0.15s ease-out;
}

.links-mobile nav ul li a:hover::before,
.links-mobile nav ul li a.active::before {
  transform: scaleX(1);
}

@media only screen and (max-width: 1120px) {
  .main-link {
    width: 60px;
    height: 80px;
  }

  .links-mobile {
    display: flex;
  }
}

@media only screen and (max-width: 600px) {
  .main-link {
    width: 50px;
    height: 70px;
  }

  .mobile-navigation {
    display: flex;
    padding: 0 20px;
  }

  @media (max-width: 600px) {
    .action-navigation .language {
      width: 25px;
      height: 25px;
    }
    .action-navigation .menu-button {
      width: 30px;
      height: 30px;
    }
  }
}
