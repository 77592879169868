@import "../../styles/_fonts.css";

.component-hero {
  display: grid;
  place-items: center;
  place-content: center;
  width: 100%;
  height: 85vh;
  position: relative;
  z-index: 1;
}

.component-hero > * {
  position: relative;
  color: white;
}

.component-hero > .full-screen-vid {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.component-hero > img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.component-hero > .layer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.component-hero .title {
  text-align: center;
  font-family: "MetrischLight", sans-serif;
}

.component-hero .title:first-of-type {
  margin-top: 80px;
}

.component-hero .subtitle {
  max-width: 980px;
  margin-top: 20px;
  font-family: "GabiantThin", sans-serif;
  text-align: center;
}

.component-hero > .main-button {
  margin-top: 80px;
}

.component-hero > .main-button:hover {
  text-decoration: none;
}

@media only screen and (max-width: 1120px) {
  .component-hero .subtitle {
    max-width: 475px;
  }
  .component-hero > .main-button {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 600px) {
  .component-hero .subtitle {
    max-width: 380px;
    margin: 20px 30px;
  }
  .component-hero > .main-button {
    margin-top: 30px;
  }
}
