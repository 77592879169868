.component-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 240px;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 100px;
  z-index: 2;
}

.component-header > * {
  width: 115px;
}

.component-header > .main-link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header-navigation {
  display: none;
  width: 172px;
  position: absolute;
  top: 100px;
  right: 100px;
  padding: 15px 0;
  background: #fff;
}

.header-navigation.active {
  display: block;
}

.header-navigation.with-shadow {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.close-row {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
}

.close-menu {
  width: 20px;
  height: 20px;
}

.close-menu > img {
  pointer-events: none;
}

.navigation-menu {
  display: flex;
  flex-direction: column;
  row-gap: 0;
  margin-top: 15px;
}

.navigation-menu > li {
  padding: 6px 0 6px 36px;
  transition: background-color 0.2s ease-out;
}

.navigation-menu > li > a {
  font-size: var(--font-size-p-two);
  text-transform: uppercase;
  color: #000;
  transition: color 0.2s ease-out;
}

.navigation-menu > li:hover,
.navigation-menu > li.active {
  background-color: #000;
}

.navigation-menu > li:hover > a,
.navigation-menu > li.active > a {
  color: #fff;
}

.component-header .action-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 30px;
  width: 314px;
}

.component-header .action-navigation .language {
  width: 30px;
  height: 30px;
}

.component-header .action-navigation .about-link,
.component-header .action-navigation .contact-link,
.component-header .action-navigation .menu-button {
  width: 35px;
  height: 35px;
}

.component-header .action-navigation .language > img,
.component-header .action-navigation .menu-button > img {
  pointer-events: none;
}

@media (max-width: 1120px) {
  .component-header {
    height: 120px;
  }

  .spacer {
    display: none;
  }

  .component-header > .main-link {
    position: static;
    transform: none;
  }

  /* .header-navigation {
    display: none;
  } */
}

/*black mode*/
.black-link a {
  color: var(--black);
}

@media (max-width: 600px) {
  .component-header .action-navigation {
    display: none;
  }
  .component-header .main-link {
    display: none;
  }
}
