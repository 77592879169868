.component-selling-point {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 40px 30px 45px;
  background: var(--white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.component-selling-point > .icon {
  width: 90px;
  height: 90px;
}

.component-selling-point > p {
  margin-top: 25px;
  font-size: var(--font-size-p-three);
  text-transform: uppercase;
  color: var(--black);
}
