.screen.fabric {
  overflow: hidden;
}

.screen.fabric > main {
  position: relative;
}

.screen.fabric > main .background-circle {
  width: 1200px;
  height: 2300px;
  position: absolute;
  top: -280px;
  left: -12vw;
  background: url("../../../public/circles-1.png") no-repeat center;
  background-size: cover;
  pointer-events: none;
}

.fabric-collection-items {
  display: flex;
  flex-direction: column;
  row-gap: 80px;
  position: relative;
  margin-top: 65px;
}

.screen.fabric .outline-white {
  font-size: var(--font-size-h3);
}

.row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: auto;
}

.roller-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 2.5vw;
  margin-top: 100px;
  position: relative;
}

.roller-grid > img {
  width: calc(25% - 1.88vw);
  height: auto;
  object-fit: cover;
  cursor: pointer;
}

@media only screen and (max-width: 1120px) {
  .screen.fabric > main .background-circle {
    width: 750px;
    height: 1450px;
    position: absolute;
    top: -100px;
    left: -20px;
  }

  .fabric-collection-items {
    row-gap: 9vw;
  }
  .row {
    flex-wrap: wrap;
  }
  .roller-grid {
    gap: 50px;
    margin-top: 75px;
  }

  .roller-grid > img {
    width: calc(33.3% - 34px);
  }
}

@media only screen and (max-width: 600px) {
  .screen.fabric > main .background-circle {
    width: 400px;
    height: 750px;
    position: absolute;
    top: 0;
    left: -50px;
  }

  .fabric-collection-items {
    row-gap: 25px;
    margin-top: 40px;
  }

  .roller-grid {
    gap: 25px;
    margin-top: 50px;
  }

  .roller-grid > img {
    width: calc(50% - 12.5px);
  }
}
