.screen.home {
  overflow: hidden;
}

.screen.home > main {
  position: relative;
}

.screen.home > main .background-circle {
  background: blue;
  position: absolute;
}

.screen.home > main .background-circle.top {
  width: 2000px;
  height: 2000px;
  top: -1200px;
  right: -1000px;
  background: url("../../../public/circles-2.png") no-repeat center;
  background-size: cover;
}

.screen.home > main .background-circle.bottom {
  width: 1200px;
  height: 1200px;
  top: 420px;
  left: -210px;
  background: url("../../../public/circles-1.png") no-repeat center;
  background-size: cover;
}

.screen.home > main > .selling-points > h5 > .outline-white {
  font-size: var(--font-size-h5);
}

.screen.home > main > .selling-points > .selling-points-list {
  margin-top: 65px;
}

.screen.home > main > .selling-points > .selling-points-pictures {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
  margin-top: 100px;
}

.screen.home > main > .selling-points > .selling-points-pictures > .picture {
  width: calc(50% - 7.5px);
}

.screen.home
  > main
  > .selling-points
  > .selling-points-pictures
  > .picture
  > .content {
  display: block;
  height: 0;
  padding-top: 56.25%;
  position: relative;
}

.screen.home
  > main
  > .selling-points
  > .selling-points-pictures
  > .picture
  > .content
  > img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

@media only screen and (max-width: 1120px) {
  .screen.home > main .background-circle.top {
    width: 1000px;
    height: 1000px;
    top: -500px;
    right: -450px;
  }

  .screen.home > main .background-circle.bottom {
    width: 600px;
    height: 1000px;
    top: auto;
    left: -10px;
    bottom: -100px;
  }

  .screen.home > main > .selling-points > .selling-points-list {
    justify-content: center;
    row-gap: 75px;
    column-gap: 75px;
  }
  .screen.home > main > .selling-points > .selling-points-pictures {
    gap: 50px;
    margin-top: 75px;
  }

  .screen.home > main > .selling-points > .selling-points-pictures > .picture {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .screen.home > main {
    padding-bottom: 100px;
  }

  .screen.home > main .background-circle.top {
    width: 500px;
    height: 500px;
    top: -250px;
    right: -275px;
  }

  .screen.home > main .background-circle.bottom {
    width: 300px;
    height: 500px;
    top: auto;
    left: -50px;
    bottom: -100px;
  }

  .screen.home > main > .selling-points > .selling-points-list {
    row-gap: 50px;
    column-gap: 50px;
    margin-top: 40px;
  }

  .screen.home > main > .selling-points > .selling-points-pictures {
    margin-top: 50px;
  }
}
