.component.picture-text-block {
  display: flex;
  flex-wrap: wrap;
}

.component.picture-text-block * {
  color: var(--white);
}

.component.picture-text-block > .title,
.component.picture-text-block > .copy {
  width: 50%;
  height: 0;
  padding-top: calc(235 / 720 * 50%);
  position: relative;
}

.component.picture-text-block > .title {
  background: var(--black);
}

.component.picture-text-block > .title > h2,
.component.picture-text-block > .copy > p {
  position: absolute;
  top: 52.5%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.component.picture-text-block > .title > h2 {
  text-transform: uppercase;
}

.component.picture-text-block > .copy {
  background: var(--gray);
}

.component.picture-text-block > .copy > p {
  width: 480px;
}

.component.picture-text-block.right > .title {
  order: 2;
}
.component.picture-text-block.right > .copy {
  order: 1;
}
.component.picture-text-block.right > .picture {
  order: 3;
}

.component.picture-text-block > .picture {
  width: 100%;
  height: 0;
  padding-top: calc(338 / 1440 * 100%);
  position: relative;
}

.component.picture-text-block > .picture > .inner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.component.picture-text-block > .picture > .inner img {
  object-fit: cover;
}

@media only screen and (max-width: 1120px) {
  .component.picture-text-block > .title,
  .component.picture-text-block > .copy {
    width: 100%;
  }

  .component.picture-text-block.right > .title {
    order: 1;
  }
  .component.picture-text-block.right > .copy {
    order: 2;
  }
  .component.picture-text-block.right > .picture {
    order: 3;
  }
}

@media only screen and (max-width: 600px) {
  .component.picture-text-block > .title,
  .component.picture-text-block > .copy {
    display: grid;
    place-items: center;
    height: auto;
    padding-top: 0;
    padding: 40px;
    position: relative;
  }

  .component.picture-text-block > .title > h2,
  .component.picture-text-block > .copy > p {
    width: 100%;
    position: static;
    transform: none;
  }

  .component.picture-text-block > .title > h2 {
    text-align: center;
    transform: translate(0, 10%);
  }

  .component.picture-text-block > .picture {
    padding-top: calc(900 / 1600 * 100%);
  }
}
