.component-option-slider {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 100px 0;
  position: relative;
  background: black;
}

.component-option-slider > .background-layer {
  width: 62.5%;
  position: absolute;
  top: 0;
  left: 0;
}

.component-option-slider > .background-layer > img {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.25s ease-in-out;
  object-fit: cover;
}

.component-option-slider > .background-layer > img.active {
  opacity: 1;
}

.component-option-slider > .inner {
  display: flex;
  width: 100%;
  max-width: 1400px;
  padding: 0 140px;
  position: relative;
}

.component-option-slider > * {
  height: 100%;
}

.component-option-slider > .inner .options {
  width: 50%;
  padding: 25px 0;
}

.component-option-slider > .inner .options * {
  color: white;
}

.component-option-slider > .inner .options h3 {
  font-family: "MetrischBook", sans-serif;
}

.component-option-slider > .inner .options ul {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  margin-top: 6vmax;
}

.component-option-slider > .inner .options ul li button {
  font-family: "MetrischBook", sans-serif;
  font-size: var(--font-size-h5);
}

.component-option-slider > .inner .options ul li button:hover,
.component-option-slider > .inner .options ul li button:focus,
.component-option-slider > .inner .options ul li button:active,
.component-option-slider > .inner .options ul li button.active {
  font-weight: 800;
}

.component-option-slider > .inner .slider {
  width: 50%;
}
.slider-content {
  width: 100%;
  height: 0;
  padding-top: calc(354 / 543 * 100%);
  position: relative;
  overflow: hidden;
}
.slider-content .slider-item {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.25s ease-in-out;
}
.slider-content .slider-item > img {
  object-fit: cover;
}
.slider-content .slider-item:nth-of-type(1) {
  transform: translateY(-101%);
}
.slider-content .slider-item:nth-of-type(2) {
  transform: translateY(101%);
}
.slider-content .slider-item.active {
  z-index: 1;
}
.slider-content .slider-item.set {
  transform: translateY(0);
}

@media only screen and (max-width: 1120px) {
  .component-option-slider {
    padding: 75px 0;
  }

  .component-option-slider > .background-layer {
    width: 100%;
  }

  .component-option-slider > .inner {
    flex-wrap: wrap;
    /* max-width: 1100px; */
    padding: 0 12.5vmax;
  }

  .component-option-slider > .inner .options {
    width: 100%;
    padding: 0;
  }

  .component-option-slider > .inner .options h3 {
    text-align: center;
  }

  .component-option-slider > .inner .options ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    row-gap: 0;
    column-gap: 150px;
    margin-top: 60px;
  }

  .component-option-slider > .inner .slider {
    width: 100%;
    margin-top: 50px;
  }

  .slider-content {
    padding-top: calc(900 / 1600 * 100%);
  }
}

@media only screen and (max-width: 600px) {
  .component-option-slider {
    padding: 50px 0;
  }

  .component-option-slider > .inner {
    padding: 0 50px;
  }

  .component-option-slider > .inner .options ul {
    column-gap: 75px;
  }
}
