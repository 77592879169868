.screen.clothing {
  overflow: hidden;
}

.screen.clothing > main {
  position: relative;
}

.screen.clothing > main .background-circle {
  position: absolute;
  pointer-events: none;
}

.screen.clothing > main .background-circle.top {
  width: 1400px;
  height: 2200px;
  top: -900px;
  right: -250px;
  background: url("../../../public/circles-2.png") no-repeat center;
  background-size: cover;
}

.screen.clothing > main .background-circle.bottom {
  width: 1400px;
  height: 2200px;
  right: -250px;
  bottom: -1800px;
  background: url("../../../public/circles-2.png") no-repeat center;
  background-size: cover;
}

.screen.clothing main .clothing-gallery {
  margin-top: 200px;
}

@media only screen and (max-width: 1120px) {
  .screen.clothing > main .background-circle.top {
    width: 700px;
    height: 1100px;
    top: -450px;
    right: -300px;
  }

  .screen.clothing > main .background-circle.bottom {
    width: 700px;
    height: 1100px;
    right: -50px;
    bottom: -900px;
  }

  .screen.clothing main .clothing-gallery {
    margin-top: 150px;
  }
}

@media only screen and (max-width: 600px) {
  .screen.clothing > main .background-circle.top {
    width: 350px;
    height: 550px;
    top: -250px;
    right: -200px;
  }

  .screen.clothing > main .background-circle.bottom {
    width: 350px;
    height: 550px;
    right: -50px;
    bottom: -420px;
  }

  .screen.clothing main .clothing-gallery {
    margin-top: 100px;
  }
}
