@import "../../styles/index.css";

.screen.contact {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}
.screen.contact .column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  height: 1662px;
  margin-top: 240px;
}
.screen.contact .column.left > img {
  object-fit: cover;
}

.screen.contact .column.right {
  padding: 60px 70px;
}

.screen.contact .column.right .contact-info {
  max-width: 550px;
  margin-top: 45px;
}

.screen.contact .column.right .contact-info .contact-item:not(:first-of-type) {
  margin-top: 40px;
}

.screen.contact .column.right .contact-info .contact-item:first-of-type hr {
  margin-top: 15px;
}

.screen.contact .column.right .contact-info .contact-item hr {
  width: 100%;
  height: 2px;
  margin: 45px 0 0;
  background: #000;
}

.screen.contact .column.right .contact-info .contact-item > h4 {
  font-family: "GabiantBold", sans-serif;
  font-size: var(--font-size-p-one);
}

.screen.contact .column.right .contact-info .contact-item .content {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.contact-content-item h4 {
  font-family: "GabiantBold", sans-serif;
  font-size: var(--font-size-p-one);
}

.contact-content-item a {
  margin-top: 20px;
  color: #000;
  text-decoration: underline;
  font-family: "MetrischBook", sans-serif;
  font-size: var(--font-size-p-one);
}

.contact-content-item:first-of-type {
  margin-top: 20px;
}

.screen.contact .column.right .contact-info .contact-item .multi-phone,
.screen.contact .column.right .contact-info .contact-item > a {
  display: flex;
  align-items: center;
  margin-top: 35px;
}

.multi-phone {
  align-items: flex-start !important;
}
.multi-phone .links {
  flex-direction: column;
}
.multi-phone .links a {
  margin: 5px 0px;
}

.screen.contact .column.right .contact-info .contact-item > a span {
  margin-top: 0;
  margin-left: 20px;
  font-family: "GabiantRegular", sans-serif;
  font-size: var(--font-size-p-one);
  color: #000;
}

.screen.contact .column.right .contact-info .contact-item .multi-phone img,
.screen.contact .column.right .contact-info .contact-item > a img {
  width: 30px;
}

.screen.contact .column.right .contact-info .contact-item .multi-phone .links {
  display: flex;
  column-gap: 5px;
  margin-left: 20px;
}

.screen.contact
  .column.right
  .contact-info
  .contact-item
  .multi-phone
  .links
  a {
  font-family: "GabiantRegular", sans-serif;
  font-size: var(--font-size-p-one);
  color: #000;
}

.contact-form-wrapper {
  margin-top: 60px;
}

.contact-form-wrapper > h4 {
  font-size: var(--font-size-p-one);
}

.contact-form-wrapper form {
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
  row-gap: 25px;
  margin-top: 50px;
}

.inputs-wrapper {
  width: 100%;
}
.textarea-wrapper {
  width: 100%;
  height: 170px;
  margin-top: 10px;
  background: red;
}

/*input styles*/
.screen.contact input,
.screen.contact textarea {
  border: 2px solid var(--black);
  background-color: var(--white);
  font-family: "GabiantRegular", sans-serif;
  display: inline-block;
  width: 100%;
  height: 42px;
  padding: 6px 10px;
  font-size: 16px;
  appearance: none;
}
.screen.contact input:focus,
.screen.contact textarea:focus {
  outline: none !important;
}
.screen.contact input {
  margin-bottom: 20px;
}
.screen.contact textarea {
  height: 100%;
  width: 100%;
  resize: none;
}
.screen.contact input:last-child {
  margin-bottom: 0px;
}
.screen.contact button {
  background-color: var(--black);
  color: var(--white);
  padding: 10px 30px;
  font-family: GabiantRegular, sans-serif;
  margin-top: 40px;
  font-size: 18px;
  align-self: flex-end;
}

@media (max-width: 1120px) {
  .screen.contact {
    flex-wrap: wrap;
  }

  .screen.contact .column {
    width: 100%;
    height: auto;
    margin-top: 0;
  }

  .screen.contact .column.left {
    height: 400px;
    margin-top: 120px;
  }

  .contact-form-wrapper form {
    max-width: none;
  }

  .inputs-wrapper,
  .textarea-wrapper {
    width: 100%;
    padding-left: 0px;
  }
}

@media (max-width: 600px) {
  .screen.contact .column.left {
    height: 300px;
    margin-top: 100px;
  }

  .screen.contact .column.right {
    padding: 50px 20px;
  }
}
