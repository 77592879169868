.component-print-item {
  width: 12%;
  max-width: none;
  height: auto;
  position: relative;
}

.component-print-item > img {
  object-fit: cover;
  width: 100%;
  height: auto;
  cursor: pointer;
  border-radius: 20px;
}

.component-print-item > .subtitle {
  margin-top: 20px;
  color: #000;
  font-family: "MetrischBook", sans-serif;
  font-size: 16px;
}

@media only screen and (max-width: 1120px) {
  .component-print-item {
    width: 30%;
    max-width: none;
  }
}

@media only screen and (max-width: 600px) {
  .component-print-item {
    width: calc(50% - 29px);
  }
}
