.component-footer {
  display: flex;
  height: 400px;
  position: relative;
  background-color: var(--black);
}

.component-footer > .menu-social {
  display: flex;
  column-gap: 50px;
  width: calc(100% - 500px);
  padding: 65px 75px 0;
}

.component-footer > .menu-social > .footer-menu,
.component-footer > .menu-social > .address {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.component-footer > .menu-social > .address {
  max-width: 280px;
}

.component-footer > .menu-social > .footer-menu h5,
.component-footer > .menu-social > .address h5 {
  font-size: var(--font-size-p-one);
  color: white;
}

.component-footer > .menu-social > .footer-menu > .navigation,
.component-footer > .menu-social > .address > p {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-top: 15px;
}

.component-footer > .menu-social > .footer-menu > .navigation > li > a,
.component-footer > .menu-social > .address p {
  font-size: var(--font-size-p-two);
  position: relative;
}

.component-footer > .menu-social > .footer-menu > .navigation > li > a::after {
  content: "";
  width: 100%;
  height: 2px;
  background: var(--white);
  position: absolute;
  left: 0;
  bottom: -6px;
  transform: scaleX(0);
  transform-origin: center left;
  transition: transform 0.2s ease-out;
}

.component-footer
  > .menu-social
  > .footer-menu
  > .navigation
  > li
  > a.active::after,
.component-footer
  > .menu-social
  > .footer-menu
  > .navigation
  > li
  > a:hover::after {
  transform: scaleX(1);
}

.component-footer > .menu-social > .social-links {
  align-self: flex-end;
  display: flex;
  align-items: center;
  column-gap: 25px;
}

.component-footer > .menu-social > .social-links > li > a {
  width: 40px;
  height: 40px;
}

.footer-map-wrapper {
  width: 600px;
  overflow: hidden;
}

.component-footer iframe {
  border: 0px !important;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

@media (max-width: 1540px) {
  .component-footer > .menu-social {
    column-gap: 50px;
    width: calc(100% - 300px);
    padding: 75px 50px 0;
  }
  .footer-map-wrapper {
    width: 300px;
  }
}

@media (max-width: 1120px) {
  .component-footer {
    justify-content: center;
    flex-wrap: wrap;
    height: auto;
    padding-bottom: 50px;
  }

  .component-footer > .menu-social {
    justify-content: flex-start;
    column-gap: 100px;
    row-gap: 50px;
    width: auto;
    padding: 50px 60px 0;
  }

  .component-footer > .menu-social > .social-links {
    align-self: flex-start;
    margin-top: 30px;
  }

  .footer-map-wrapper {
    width: 500px;
    height: 350px;
    margin-top: 50px;
  }
}

@media (max-width: 824px) {
  .component-footer > .menu-social {
    justify-content: space-between;
    column-gap: auto;
    flex-wrap: wrap;
    max-width: 600px;
  }
}
@media (max-width: 600px) {
  .component-footer {
    flex-wrap: wrap;
    height: auto;
    padding-bottom: 0;
  }

  .component-footer > .menu-social {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 50px;
  }

  .component-footer > .menu-social > .footer-menu,
  .component-footer > .menu-social > .address {
    align-items: center;
  }

  .component-footer > .menu-social > .address p {
    text-align: center;
  }

  .component-footer > .menu-social > .footer-menu > .navigation {
    align-items: center;
  }

  .component-footer > .menu-social > .social-links {
    align-self: center;
    margin-top: 20px;
  }

  .footer-map-wrapper {
    width: 100%;
    height: 250px;
    margin-top: 0;
  }
}
