.screen.eco > main {
  position: relative;
  padding-bottom: 244px;
}

.screen.eco > main .background-circle {
  width: 1650px;
  height: 2050px;
  position: absolute;
  top: -700px;
  left: -200px;
  background: url("../../../public/circles-3.png") no-repeat center;
  background-size: cover;
}

.screen.eco > main > .eco-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.screen.eco > main > .eco-section > p {
  margin-top: 50px;
  text-align: center;
  color: #000;
}

@media only screen and (max-width: 1120px) {
  .screen.eco > main {
    padding-bottom: 110px;
  }

  .screen.eco > main .background-circle {
    width: 825px;
    height: 1025px;
    top: -350px;
    left: -100px;
  }
}

@media only screen and (max-width: 600px) {
  .screen.eco > main {
    padding-bottom: 60px;
  }
  .screen.eco > main > .eco-section > p {
    margin-top: 25px;
  }

  .screen.eco > main .background-circle {
    width: 420px;
    height: 775px;
    top: -200px;
    left: -150px;
  }
}
.eco-blended {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 75px;
  padding: 50px 100px;
  position: relative;
  background: white;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  width: 80%;
}
.eco-blended img {
  width: 40%;
  height: auto !important;
}
.eco-blended p {
  color: #000;
  font: 20px;
  margin-bottom: 25px;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .eco-blended {
    width: 100%;
  }
  .eco-blended {
    padding: 25px;
  }
}
