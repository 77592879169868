.component-picture-grid {
  justify-content: center;
}

.component-picture-grid h2 {
  text-align: center;
}

.component-picture-grid .pictures {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 48px;
  margin-top: 100px;
}

.component-picture-grid .pictures img {
  width: calc(25% - 40px);
  border-radius: 10px;
  overflow: hidden;
  object-fit: cover;
  cursor: pointer;
}

@media only screen and (max-width: 1120px) {
  .component-picture-grid .pictures {
    row-gap: 42px;
  }

  .component-picture-grid .pictures img {
    min-width: calc(33.33% - 30px);
  }
}

@media only screen and (max-width: 600px) {
  .component-picture-grid .pictures {
    margin-top: 75px;
  }

  .component-picture-grid .pictures {
    row-gap: 42px;
  }

  .component-picture-grid .pictures img {
    min-width: calc(50% - 12.5px);
  }
}
