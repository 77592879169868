.screen.about-us {
  width: 100%;
}
.screen.about-us main {
  max-width: none;
  padding: 0;
  padding-bottom: 300px;
}

.what-we-do {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-top:100px;
}

.what-we-do .presentation {
  width: 100%;
  max-width: 685px;
  margin: 150px 0;
}

.what-we-do .presentation p {
  color: var(--black);
}

.focus-points {
  display: flex;
  flex-direction: column;
  row-gap: 150px;
}

@media only screen and (max-width: 1120px) {
  .what-we-do .presentation {
    width: 100%;
    max-width: 1100px;
    margin: 100px 12.5vmax;
  }

  .screen.about-us main {
    padding-bottom: 150px;
  }

  .focus-points {
    row-gap: 100px;
  }
}

@media only screen and (max-width: 600px) {
  .what-we-do .presentation {
    margin: 50px;
  }
  .screen.about-us main {
    padding-bottom: 100px;
  }

  .focus-points {
    row-gap: 75px;
  }
}

.options .active {
  font-family: "MetrischBold" !important;
}
.options h3 {
  font-family: "MetrischLight" !important;
}
