.component-key-point {
  display: flex;
  width: 100%;
  min-height: 475px;
  padding: 80px 50px;
  position: relative;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.print-collections > .collection > .content {
  align-items: center;
  justify-content: center;
}

.component-key-point .part {
  display: flex;
  width: 50%;
}

.point-line {
  min-width: 3px;
  height: 100%;
  background: #000;
  position: relative;
  top: 0;
  left: 0;
}

.point-line::after {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 50%;
  background: #000;
  border-radius: 50%;
  transform: translate(-50%);
}

.point-line.one::after {
  top: 20%;
}

.point-line.two::after {
  top: 40%;
}

.point-line.three::after {
  top: 60%;
}

.point-line.four::after {
  top: 80%;
}

.component-key-point .part .content {
  display: flex;
  flex-direction: column;
  margin: -5px 50px 0;
}

.component-key-point .part .content .title {
  font-weight: 700;
}

.component-key-point .part .content .outline-white {
  margin-left: 10px;
  font-size: var(--font-size-h4);
}

.component-key-point .part .text-content {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 75px;
  margin-top: 10px;
}

.component-key-point .part .text-content * {
  color: #000;
  font-size: 1.2rem;
  max-width: auto;
  margin-right: 0px !important;
}

.component-key-point .part .text-content .copy.second {
  margin-top: 30px;
  max-width: 350px !important;
}

.component-key-point .part .content a {
  display: flex;
  align-items: center;
  color: #000;
}

.component-key-point .part .content a:hover span {
  transform: translate(10px);
}

.component-key-point .part .content a span {
  width: 26px;
  background: url("../../../../public/icons/flecha.svg") no-repeat center;
  background-size: contain;
  margin-left: 10px;
  position: relative;
  top: 4%;
  transition: transform 0.2s ease-out;
}

.component-key-point .part .text-content .key-points-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 160px;
  margin-top: 30px;
  list-style-type: disc !important;
  padding-left: 20px;
}

.component-key-point .part.right img {
  object-fit: cover;
}

@media (max-width: 1540px) {
  .component-key-point .part .content {
    margin: -5px 30px 0 30px;
  }
}

@media only screen and (max-width: 1270px) {
  .component-key-point {
    flex-direction: column;
    row-gap: 50px;
    height: auto;
  }
  .point-line {
    height: calc(100% - 160px);
    position: absolute;
    top: 50%;
    left: 50px;
    transform: translateY(-50%);
  }

  .component-key-point .part {
    width: auto;
    margin-left: 50px;
  }

  .component-key-point .part.left .content {
    align-items: flex-start;
    margin: 0;
  }

  .component-key-point .part .text-content {
    row-gap: 25px;
  }

  .component-key-point .part .text-content * {
    max-width: none;
    font-size: 1.25rem;
  }

  .component-key-point .part .text-content .copy {
    max-width: 450px;
  }
}

@media only screen and (max-width: 600px) {
  .component-key-point {
    row-gap: 25px;
    min-height: 0;
    padding: 40px 20px 25px;
  }

  .component-key-point .part {
    margin-left: 0;
  }

  .component-key-point .part .text-content {
    min-height: 120px;
  }

  .component-key-point .part .content .outline-white {
    margin-left: 5px;
  }

  .point-line {
    width: 100%;
    height: 3px;
    position: relative;
    top: 0;
    left: 0;
  }

  .point-line::after {
    width: 25px;
    height: 25px;
    transform: translate(-50%, -45%);
  }

  .point-line.one::after {
    left: 20%;
  }
  .point-line.two::after {
    left: 40%;
  }
  .point-line.three::after {
    left: 60%;
  }
  .point-line.four::after {
    left: 80%;
  }

  .component-key-point .part .text-content .key-points-list {
    flex-wrap: nowrap;
    margin-right: 0px !important;
  }
}
