.component-picture-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin-top: 100px;
}

.component-picture-block > .pictures {
  display: flex;
  column-gap: 40px;
  width: calc(100% - 405px);
  padding: 40px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.component-picture-block > .pictures .inner {
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
}

.component-picture-block > .pictures .inner img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  cursor: pointer;
}

.component-picture-block > .pictures > .three-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5%;
  width: 22.5%;
}

.component-picture-block > .pictures > .three-column > img {
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  cursor: pointer;
}

.component-picture-block > .pictures > .main-picture {
  width: 72.5%;
}

.component-picture-block > .pictures > .main-picture > img {
  width: 100%;
  object-fit: cover;
  cursor: pointer;
}

.component-picture-block > .info-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 330px;
  height: 100%;
  padding: 40px 0;
}

.component-picture-block > .info-text > .text-item p {
  display: block;
  margin-top: 10px;
  color: var(--black);
}

@media only screen and (max-width: 1120px) {
  .component-picture-block {
    flex-wrap: wrap;
    width: 100%;
    margin-top: 55px;
  }

  .component-picture-block > .pictures {
    width: 100%;
  }

  .component-picture-block > .info-text {
    flex-direction: row;
    width: 100%;
    height: auto;
    padding: 40px 0 0;
  }

  .component-picture-block > .info-text > .text-item {
    width: 75%;
    min-width: 500px;
  }
}

@media only screen and (max-width: 600px) {
  .component-picture-block {
    margin-top: 30px;
  }

  .component-picture-block > .pictures {
    flex-wrap: wrap;
    width: 100%;
    padding: 20px;
  }

  .component-picture-block > .pictures > .three-column {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 25px;
  }

  .component-picture-block > .pictures > .three-column > img {
    width: calc(50% - 12.5px);
    min-width: 230px;
    height: 39.5vw;
    min-height: 230px;
    object-fit: cover;
    flex: 1;
  }

  .component-picture-block > .pictures > .three-column > img:nth-of-type(3) {
    width: 100%;
    height: 230px;
  }

  .component-picture-block > .pictures > .main-picture {
    width: 100%;
    margin-top: 25px;
  }

  .component-picture-block > .info-text > .text-item {
    width: 100%;
    min-width: 0;
  }
}
