.screen.production {
  overflow: hidden;
}

.component-key-point .part .content {
  padding: 0px 0px;
}

.screen.production > main {
  position: relative;
  padding-bottom: 244px;
}

.screen.production > main .background-circle {
  width: 1500px;
  height: 2100px;
  position: absolute;
  top: 200px;
  right: -500px;
  background: url("../../../public/circles-2.png") no-repeat center;
  background-size: cover;
}

.screen.production > main > .key-points {
  max-width: 1250px;
}

.screen.production > main > .key-points > .key-points-texts {
  display: flex;
  flex-wrap: wrap;
  column-gap: 120px;
  row-gap: 125px;
  width: 100%;
  margin-top: 100px;
}

@media only screen and (max-width: 1120px) {
  .screen.production > main {
    padding-bottom: 110px;
  }

  .screen.production > main .background-circle {
    width: 750px;
    height: 1050px;
    position: absolute;
    top: 100px;
    right: -300px;
  }

  .screen.production > main > .key-points > .key-points-texts {
    column-gap: 10vw;
    row-gap: 75px;
    margin-top: 100px;
  }
}

@media only screen and (max-width: 600px) {
  .screen.production > main {
    padding-bottom: 60px;
  }

  .screen.production > main .background-circle {
    width: 500px;
    height: 700px;
    position: absolute;
    top: 100px;
    right: -250px;
  }

  .screen.production > main > .key-points > .key-points-texts {
    row-gap: 50px;
    margin-top: 50px;
  }

  .component-key-point:nth-of-type(1) .part .text-content .key-points-list {
    max-height: 220px !important;
  }

  .component-key-point:nth-of-type(2) .part .text-content .key-points-list {
    max-height: 320px !important;
    margin-bottom:40px;
  }
}

.key-points-list li {
  font-size: 1.1rem !important;
}
