.component-pictures-card {
  width: 100%;
  margin-top: 75px;
  padding: 50px 100px;
  position: relative;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.component-pictures-card img {
  border-radius: 10px;
  cursor: pointer;
}

.component-pictures-card > .row {
  display: flex;
  justify-content: center;
  column-gap: 2.5vw;
}

.component-pictures-card > .row:not(:first-of-type) {
  margin-top: 2.5vw;
}

.component-pictures-card > .row > img {
  width: calc(25% - 2vw);
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}

@media only screen and (max-width: 1120px) {
  .component-pictures-card {
    padding: 50px;
  }
  .component-pictures-card > .row {
    gap: 50px;
  }

  .component-pictures-card > .row:not(:first-of-type) {
    margin-top: 50px;
  }

  .component-pictures-card > .row > img {
    width: calc(50% - 25px);
    position: relative;
    border-radius: 10px;
    overflow: hidden;
  }
}

@media only screen and (max-width: 600px) {
  .component-pictures-card {
    margin-top: 50px;
    padding: 20px;
  }
  .component-pictures-card > .row {
    display: flex;
    justify-content: center;
    gap: 25px;
  }

  .component-pictures-card > .row:not(:first-of-type) {
    margin-top: 25px;
  }
  .component-pictures-card > .row > img {
    width: calc(50% - 12.5px);
    position: relative;
  }
}
