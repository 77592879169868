@import "_fonts.css";

/*Reset*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "GabiantRegular", sans-serif;
  font-weight: inherit;
}

html {
  font-size: 16px;
}

/*Variables*/
:root {
  --gray: #6a6464;
  --white: #ffffff;
  --black: #000000;
  --font-size-h1: 4.5rem;
  --font-size-h2: 3.375rem;
  --font-size-h3: 3rem;
  --font-size-h4: 2.5rem;
  --font-size-h5: 2.25rem;
  --font-size-p-one: 1.5rem;
  --font-size-p-two: 1.125rem;
  --font-size-p-three: 0.875rem;
}

body {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

.screen {
  display: flex;
  flex-direction: column;
  align-items: center;
}

main {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 1500px;
  padding: 0 150px 300px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "MetrischBold", sans-serif;
}

h1 {
  font-size: var(--font-size-h1);
}

h2 {
  font-size: var(--font-size-h2);
}

h3 {
  font-size: var(--font-size-h3);
}

h4 {
  font-size: var(--font-size-h4);
}

h5 {
  font-size: var(--font-size-h5);
}

p,
span,
a {
  font-family: "GabiantRegular", sans-serif;
  font-size: var(--font-size-p-one);
  font-weight: 400;
  color: var(--white);
}

a,
button {
  display: block;
  transition: all 0.2s ease;
  cursor: pointer;
}

a {
  text-decoration: none;
  position: relative;
}

.main-button {
  font-size: var(--font-size-h3);
}

button {
  appearance: none;
  background: none;
  border: none;
}

.section-title {
  margin-top: 100px;
}

ul {
  list-style-type: none;
}

.main-button {
  display: grid;
  place-items: center;
  width: 200px;
  height: 70px;
  background: black;
  color: white;
}

.main-button:hover {
  background: white;
  color: black;
}

section > img,
article > img,
div > img,
div > svg,
a > img,
a > svg,
button > img,
button > svg {
  width: 100%;
  height: 100%;
}

section {
  width: 100%;
}

img {
  max-width: 100%;
}

.screen {
  position: relative;
}

.outline-white {
  font-family: "MetrischBold", sans-serif;
  font-size: var(--font-size-h3);
  text-shadow: 0.5px 0.5px 1px #000, -0.5px -0.5px 1px #000;
  font-weight: 600;
}

.big-needle {
  width: 75%;
  margin-top: 214px;
}

.selling-points-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width:1200px;
  margin-top: 100px;
}

.component-selling-point:nth-of-type(3) > .icon {
  width: 75px;
  height: 75px;
  margin-top: 15px;
}

@media only screen and (max-width: 1120px) {
  :root {
    --font-size-h1: 3.375rem;
    --font-size-h2: 3rem;
    --font-size-h3: 2.5rem;
    --font-size-h4: 2.25rem;
    --font-size-h5: 2.125rem;
    --font-size-p-one: 1.25rem;
    --font-size-p-two: 1rem;
    --font-size-p-three: 0.875rem;
  }

  main {
    max-width: 1100px;
    padding: 0 10vmin 150px;
  }

  .section-title {
    margin-top: 100px;
    text-align: center;
  }

  .selling-points-list {
    justify-content: center;
    row-gap: 75px;
    column-gap: 75px;
  }

  .big-needle {
    margin-top: 100px;
  }
}

@media (max-width: 600px) {
  :root {
    --font-size-h1: 1.85rem;
    --font-size-h2: 1.6rem;
    --font-size-h3: 1.4rem;
    --font-size-h4: 1.25rem;
    --font-size-h5: 1.25rem;
    --font-size-p: 1rem;
    --font-size-small: 0.8rem;
    --font-size-p-one: 1rem;
    --font-size-p-two: 1rem;
    --font-size-p-three: 1rem;
  }

  main {
    max-width: calc(100% - 50px);
    padding: 0;
    padding-bottom: 100px;
  }

  .big-needle {
    margin-top: 50px;
  }

  .main-button {
    display: grid;
    place-items: center;
    width: 150px;
    height: 50px;
    background: black;
    color: white;
  }

  .section-title {
    margin-top: 50px;
  }

  .selling-points-list {
    row-gap: 50px;
    column-gap: 50px;
    margin-top: 40px;
  }

}
