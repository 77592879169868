.component-picture-row {
  width: 100%;
  position: relative;
}

.component-picture-row:first-child {
  margin-top: 100px;
}

.component-picture-row:not(:first-child) {
  margin-top: 100px;
}

.component-picture-row > .pictures {
  display: flex;
  justify-content: space-between;
  column-gap: 2.5vw;
  margin-top: 75px;
}

.component-picture-row > .pictures > img {
  height: auto;
  object-fit: cover;
  cursor: pointer;
  width: calc(20% - 40px);
  border-radius: 10px;
}

@media only screen and (max-width: 1120px) {
  .component-picture-row:first-child {
    margin-top: 75px;
  }

  .component-picture-row:not(:first-child) {
    margin-top: 75px;
  }

  .component-picture-row > h3 {
    text-align: center;
  }

  .component-picture-row > .pictures {
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 8vw;
    row-gap: 8vw;
    margin-top: 50px;
  }
  .component-picture-row > .pictures > img {
    width: calc(30% - 40px);
  }
}

@media only screen and (max-width: 600px) {
  .component-picture-row:first-child {
    margin-top: 50px;
  }
  .component-picture-row:not(:first-child) {
    margin-top: 50px;
  }
  .component-picture-row > .pictures {
    margin-top: 30px;
  }
  .component-picture-row > .pictures > img {
    width: calc(50% - 40px);
  }
}
