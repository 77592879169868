/*Fonts Face*/

/*Babiant*/
@font-face {
  font-family: "GabiantBold";
  src: local("GabiantBold"), url(../fonts/Gabiant-ExtraBold.woff) format("woff"),
    local("GabiantBold"), url(../fonts/Gabiant-ExtraBold.woff2) format("woff2");
}
@font-face {
  font-family: "GabiantRegular";
  src: local("GabiantRegular"),
    url(../fonts/Gabiant-Regular.woff) format("woff"), local("GabiantRegular"),
    url(../fonts/Gabiant-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "GabiantThin";
  src: local("GabiantThin"), url(../fonts/Gabiant-Thin.woff) format("woff"),
    local("GabiantThin"), url(../fonts/Gabiant-Thin.woff2) format("woff2");
}

/*Metrisch*/
@font-face {
  font-family: "MetrischBold";
  src: local("MetrischBold"), url(../fonts/Metrisch-Bold.woff) format("woff"),
    local("MetrischBold"), url(../fonts/Metrisch-Bold.woff2) format("woff2");
}
@font-face {
  font-family: "MetrischBook";
  src: local("MetrischBook"), url(../fonts/Metrisch-Book.woff) format("woff"),
    local("MetrischBook"), url(../fonts/Metrisch-Book.woff2) format("woff2");
}
@font-face {
  font-family: "MetrischLight";
  src: local("MetrischBook"), url(../fonts/Metrisch-Light.woff) format("woff"),
    local("MetrischBook"), url(../fonts/Metrisch-Light.woff2) format("woff2");
}
