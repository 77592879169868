.component-fabric-item {
  width: calc(25% - 37.5px);
  padding: 20px 20px 30px;
  height: auto;
  position: relative;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.component-fabric-item > .inner {
  height: 0;
  padding-top: 100%;
  position: relative;
}

.component-fabric-item > .inner > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.component-fabric-item > h5 {
  margin-top: 25px;
  text-align: center;
  font-size: var(--font-size-p-two);
  color: var(--black);
}

@media only screen and (max-width: 1120px) {
  .component-fabric-item {
    width: calc(50% - 5vw);
    height: auto;
    position: relative;
  }
  .component-fabric-item:nth-of-type(3),
  .component-fabric-item:nth-of-type(4),
  .component-fabric-item:nth-of-type(7),
  .component-fabric-item:nth-of-type(8) {
    margin-top: 9vw;
  }
}

@media only screen and (max-width: 600px) {
  .component-fabric-item {
    width: calc(50% - 12.5px);
  }

  .component-fabric-item:nth-of-type(3),
  .component-fabric-item:nth-of-type(4),
  .component-fabric-item:nth-of-type(7),
  .component-fabric-item:nth-of-type(8) {
    margin-top: 25px;
  }
}
